import colors from "tokens/build/js/semantic-colors";
import { coreColors } from "tokens/build/js/core-colors";

export type CUICoreColorType = typeof coreColors[number];

export const semanticColors = colors;
export const semanticColorKeys = [
    "fg-default",
    "fg-moderate",
    "fg-subtle",
    "fg-minimal",
    "fg-inverse",
    "fg-primary",
    "fg-primary-strong",
    "fg-primary-minimal",
    "fg-global-black",
    "fg-global-white",
    "fg-focus",
    "fg-focus-inverse",
    "bg-base",
    "bg-default",
    "bg-layer-one",
    "bg-layer-two",
    "bg-layer-three",
    "bg-primary",
    "bg-primary-strong",
    "bg-primary-minimal",
    "highlight-accent",
    "highlight-brand",
    "highlight-one",
    "highlight-two",
    "highlight-three",
    "highlight-four",
    "status-positive",
    "status-negative",
    "status-notification",
    "status-alert",
    "rewards-background"
] as const;

export type SemanticColorStatusType = "status-positive" |
"status-negative" |
"status-notification" |
"status-alert";

export type SemanticColorRewardsType = "rewards-background";

export type SemanticColorHighlightType = 
"highlight-accent" |
"highlight-brand" |
"highlight-one" |
"highlight-two" |
"highlight-three" |
"highlight-four";

export type SemanticColorFgType = "fg-default" |
"fg-moderate" |
"fg-subtle" |
"fg-minimal" |
"fg-inverse" |
"fg-primary" |
"fg-primary-strong" |
"fg-primary-minimal" |
"fg-global-black" |
"fg-global-white" |
"fg-focus" |
"fg-focus-inverse" 
;

export type SemanticColorBgType = "fg-default" |
"bg-base" |
"bg-default" |
"bg-layer-one" |
"bg-layer-two" |
"bg-layer-three" |
"bg-primary" |
"bg-primary-strong" |
"bg-primary-minimal";

export type SemanticColorType = SemanticColorFgType | SemanticColorBgType | SemanticColorHighlightType | SemanticColorStatusType | SemanticColorRewardsType;

export const tailwindSemanticColors: Record<SemanticColorType | string, string> = {
    "fg-default": "cui-text-fg-default",
    "fg-moderate": "cui-text-fg-moderate",
    "fg-subtle": "cui-text-fg-subtle",
    "fg-minimal": "cui-text-fg-minimal",
    "fg-inverse": "cui-text-fg-inverse",
    "fg-primary": "cui-text-fg-primary",
    "fg-primary-strong": "cui-text-fg-primary-strong",
    "fg-primary-minimal": "cui-text-fg-primary-minimal",
    "fg-global-black": "cui-text-fg-global-black",
    "fg-global-white": "cui-text-fg-global-white",
    "fg-text-link": "cui-text-fg-text-link",
    "fg-focus": "cui-text-fg-focus",
    "fg-focus-inverse": "cui-text-fg-focus-inverse",
    "bg-base": "cui-text-bg-base",
    "bg-default": "cui-text-bg-default",
    "bg-layer-one": "cui-text-bg-layer-one",
    "bg-layer-two": "cui-text-bg-layer-two",
    "bg-layer-three": "cui-text-bg-layer-three",
    "bg-primary": "cui-text-bg-primary",
    "bg-primary-strong": "cui-text-bg-primary-strong",
    "bg-primary-minimal": "cui-text-bg-primary-minimal",
    "highlight-accent": "cui-text-highlight-accent",
    "highlight-brand": "cui-text-highlight-brand",
    "highlight-one": "cui-text-highlight-one",
    "highlight-two": "cui-text-highlight-two",
    "highlight-three": "cui-text-highlight-three",
    "highlight-four": "cui-text-highlight-four",
    "status-positive": "cui-text-status-positive",
    "status-negative": "cui-text-status-negative",
    "status-notification": "cui-text-status-notification",
    "status-alert": "cui-text-status-alert",
    "rewards-background": "cui-text-rewards-background"
}

export const tailwindSemanticBgColors: Record<SemanticColorType | string, string> = {
    "bg-base": "cui-bg-bg-base",
    "bg-default": "cui-bg-bg-default",
    "bg-layer-one": "cui-bg-bg-layer-one",
    "bg-layer-two": "cui-bg-bg-layer-two",
    "bg-layer-three": "cui-bg-bg-layer-three",
    "bg-primary": "cui-bg-bg-primary",
    "bg-primary-strong": "cui-bg-bg-primary-strong",
    "bg-primary-minimal": "cui-bg-bg-primary-minimal",
    "highlight-accent": "cui-bg-highlight-accent",
    "highlight-brand": "cui-bg-highlight-brand",
    "highlight-one": "cui-bg-highlight-one",
    "highlight-two": "cui-bg-highlight-two",
    "highlight-three": "cui-bg-highlight-three",
    "highlight-four": "cui-bg-highlight-four",
    "status-positive": "cui-bg-status-positive",
    "status-negative": "cui-bg-status-negative",
    "status-notification": "cui-bg-status-notification",
    "status-alert": "cui-bg-status-alert",
    "rewards-background": "cui-bg-rewards-background"
}