import { DetailMenOnBaseType, DetailProps, DetailTypes } from "../types";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import type { SizeType } from "components/src/shared/types";

dayjs.extend(isToday);
dayjs.extend(isTomorrow);

export const detailPresets: Record<
  DetailTypes,
  (tag: DetailProps) => DetailProps | DetailProps[]
> = {
  "men-on-base": ({ options }: any) => {
    return ({
      showDivider: false,
      leftIcon: { name: "icon_ui_baseball_diamond", size: "lg", options, className: "cui-absolute" }
    })
  },
  date: ({ date = "", type, ...rest}: DetailProps & { date?: string | number }) => {
    const _date = !!date ? dayjs(date) : "";
    const rightNow = dayjs(new Date());
    const diff = rightNow.diff(_date, "days");

    const isToday = _date && _date.isToday();
    const isTomorrow = _date && _date.isTomorrow();
    const isValidDate = _date && _date.isValid();

    let displayedDate = _date && _date.format("ddd").toUpperCase();
    if (isTomorrow) displayedDate = "Tomorrow";
    if (isToday) displayedDate = "Today";
    if (Math.abs(diff) >= 6)
      displayedDate = _date && _date.format("MMM D").toUpperCase();
    return isValidDate ? [
      { tag: isValidDate ? displayedDate : "", ...rest },
      { tag: isValidDate ? _date.format("h:mm A") : "", showDivider: true, ...rest },
    ] : [{ tag: "---", fgColor: "fg-subtle"}, { tag: "---", fgColor: "fg-subtle"}];
  },
  live: ({ textSize, fgColor = "highlight-accent" }) => ({
    leftIcon: { name: "ui_live_animated", size: 8 },
    tag: "Live",
    fgColor,
    textSize
  }),
  livestream: ({ tag, textSize }) => ({
    leftIcon: { name: "ui_watch", size: "md", color: "highlight-accent" },
    tag: tag ? tag : "Watch",
    textSize
  }),
  highlight: ({ textSize, fgColor = "highlight-accent"}) => ({
    textSize,
    fgColor
  }),
  "highlight-bg": ({textSize, bgColor = "ultramarine-300", fgColor = "highlight-accent"}) => ({
    textSize,
    bgColor,
    fgColor,
  }),
  signpost: ({textSize}) => ({
    textSize: textSize ?? "display-xs",
    fgColor: "fg-moderate",
  }),
  suspended: ({textSize}) => ({
    tag: "Suspended",
    fgColor: "status-negative",
    textSize,
  }),
  "rain-delay": ({textSize}) => ({
    tag: "Rain Delay",
    fgColor: "status-negative",
    textSize,
  }),
  "firebets": ({ textSize }) => ({
    tag: "", leftIcon: { name: "ui_fire-bets_color", size: "md" }, textSize
  }),
  "sgp": ({ size = "md", leftIcon }) => {
    const defaultTag = { name: "ui_sgp", size: "md" as SizeType, color: "fg-primary" };
    if (typeof leftIcon === "object") return ({ tag: "", leftIcon: { ...defaultTag, ...leftIcon } });
    return {...defaultTag, leftIcon: {...defaultTag, size}}
  }
};
