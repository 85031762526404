exports.components = {
  "component---src-layouts-changelog-index-js": () => import("./../../../src/layouts/changelog/index.js" /* webpackChunkName: "component---src-layouts-changelog-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-component-status-mdx": () => import("./../../../src/pages/component-status.mdx" /* webpackChunkName: "component---src-pages-component-status-mdx" */),
  "component---src-pages-components-avatar-mdx": () => import("./../../../src/pages/components/avatar.mdx" /* webpackChunkName: "component---src-pages-components-avatar-mdx" */),
  "component---src-pages-components-badge-mdx": () => import("./../../../src/pages/components/badge.mdx" /* webpackChunkName: "component---src-pages-components-badge-mdx" */),
  "component---src-pages-components-boxscore-index-mdx": () => import("./../../../src/pages/components/boxscore/index.mdx" /* webpackChunkName: "component---src-pages-components-boxscore-index-mdx" */),
  "component---src-pages-components-boxscore-live-mdx": () => import("./../../../src/pages/components/boxscore/live.mdx" /* webpackChunkName: "component---src-pages-components-boxscore-live-mdx" */),
  "component---src-pages-components-boxscore-pregame-mdx": () => import("./../../../src/pages/components/boxscore/pregame.mdx" /* webpackChunkName: "component---src-pages-components-boxscore-pregame-mdx" */),
  "component---src-pages-components-button-mdx": () => import("./../../../src/pages/components/button.mdx" /* webpackChunkName: "component---src-pages-components-button-mdx" */),
  "component---src-pages-components-card-mdx": () => import("./../../../src/pages/components/card.mdx" /* webpackChunkName: "component---src-pages-components-card-mdx" */),
  "component---src-pages-components-cardstack-mdx": () => import("./../../../src/pages/components/cardstack.mdx" /* webpackChunkName: "component---src-pages-components-cardstack-mdx" */),
  "component---src-pages-components-carousel-mdx": () => import("./../../../src/pages/components/carousel.mdx" /* webpackChunkName: "component---src-pages-components-carousel-mdx" */),
  "component---src-pages-components-competitor-mdx": () => import("./../../../src/pages/components/competitor.mdx" /* webpackChunkName: "component---src-pages-components-competitor-mdx" */),
  "component---src-pages-components-countdown-mdx": () => import("./../../../src/pages/components/countdown.mdx" /* webpackChunkName: "component---src-pages-components-countdown-mdx" */),
  "component---src-pages-components-datedetails-mdx": () => import("./../../../src/pages/components/datedetails.mdx" /* webpackChunkName: "component---src-pages-components-datedetails-mdx" */),
  "component---src-pages-components-details-mdx": () => import("./../../../src/pages/components/details.mdx" /* webpackChunkName: "component---src-pages-components-details-mdx" */),
  "component---src-pages-components-drawer-mdx": () => import("./../../../src/pages/components/drawer.mdx" /* webpackChunkName: "component---src-pages-components-drawer-mdx" */),
  "component---src-pages-components-forms-form-input-mdx": () => import("./../../../src/pages/components/forms/form-input.mdx" /* webpackChunkName: "component---src-pages-components-forms-form-input-mdx" */),
  "component---src-pages-components-forms-index-mdx": () => import("./../../../src/pages/components/forms/index.mdx" /* webpackChunkName: "component---src-pages-components-forms-index-mdx" */),
  "component---src-pages-components-forms-search-input-mdx": () => import("./../../../src/pages/components/forms/search-input.mdx" /* webpackChunkName: "component---src-pages-components-forms-search-input-mdx" */),
  "component---src-pages-components-forms-text-field-mdx": () => import("./../../../src/pages/components/forms/text-field.mdx" /* webpackChunkName: "component---src-pages-components-forms-text-field-mdx" */),
  "component---src-pages-components-icon-mdx": () => import("./../../../src/pages/components/icon.mdx" /* webpackChunkName: "component---src-pages-components-icon-mdx" */),
  "component---src-pages-components-index-mdx": () => import("./../../../src/pages/components/index.mdx" /* webpackChunkName: "component---src-pages-components-index-mdx" */),
  "component---src-pages-components-item-mdx": () => import("./../../../src/pages/components/item.mdx" /* webpackChunkName: "component---src-pages-components-item-mdx" */),
  "component---src-pages-components-legs-mdx": () => import("./../../../src/pages/components/legs.mdx" /* webpackChunkName: "component---src-pages-components-legs-mdx" */),
  "component---src-pages-components-loading-mdx": () => import("./../../../src/pages/components/loading.mdx" /* webpackChunkName: "component---src-pages-components-loading-mdx" */),
  "component---src-pages-components-market-button-mdx": () => import("./../../../src/pages/components/market-button.mdx" /* webpackChunkName: "component---src-pages-components-market-button-mdx" */),
  "component---src-pages-components-markets-index-mdx": () => import("./../../../src/pages/components/markets/index.mdx" /* webpackChunkName: "component---src-pages-components-markets-index-mdx" */),
  "component---src-pages-components-markets-market-footer-mdx": () => import("./../../../src/pages/components/markets/market-footer.mdx" /* webpackChunkName: "component---src-pages-components-markets-market-footer-mdx" */),
  "component---src-pages-components-markets-market-header-mdx": () => import("./../../../src/pages/components/markets/market-header.mdx" /* webpackChunkName: "component---src-pages-components-markets-market-header-mdx" */),
  "component---src-pages-components-markets-market-templates-index-mdx": () => import("./../../../src/pages/components/markets/market-templates/index.mdx" /* webpackChunkName: "component---src-pages-components-markets-market-templates-index-mdx" */),
  "component---src-pages-components-overlay-half-sheet-mdx": () => import("./../../../src/pages/components/overlay/half-sheet.mdx" /* webpackChunkName: "component---src-pages-components-overlay-half-sheet-mdx" */),
  "component---src-pages-components-overlay-index-mdx": () => import("./../../../src/pages/components/overlay/index.mdx" /* webpackChunkName: "component---src-pages-components-overlay-index-mdx" */),
  "component---src-pages-components-overlay-modal-mdx": () => import("./../../../src/pages/components/overlay/modal.mdx" /* webpackChunkName: "component---src-pages-components-overlay-modal-mdx" */),
  "component---src-pages-components-overlay-sheet-mdx": () => import("./../../../src/pages/components/overlay/sheet.mdx" /* webpackChunkName: "component---src-pages-components-overlay-sheet-mdx" */),
  "component---src-pages-components-pill-nav-mdx": () => import("./../../../src/pages/components/pill-nav.mdx" /* webpackChunkName: "component---src-pages-components-pill-nav-mdx" */),
  "component---src-pages-components-price-mdx": () => import("./../../../src/pages/components/price.mdx" /* webpackChunkName: "component---src-pages-components-price-mdx" */),
  "component---src-pages-components-progress-bar-mdx": () => import("./../../../src/pages/components/progress-bar.mdx" /* webpackChunkName: "component---src-pages-components-progress-bar-mdx" */),
  "component---src-pages-components-quick-pick-mdx": () => import("./../../../src/pages/components/quick-pick.mdx" /* webpackChunkName: "component---src-pages-components-quick-pick-mdx" */),
  "component---src-pages-components-score-mdx": () => import("./../../../src/pages/components/score.mdx" /* webpackChunkName: "component---src-pages-components-score-mdx" */),
  "component---src-pages-components-skeleton-mdx": () => import("./../../../src/pages/components/skeleton.mdx" /* webpackChunkName: "component---src-pages-components-skeleton-mdx" */),
  "component---src-pages-components-skip-link-mdx": () => import("./../../../src/pages/components/skip-link.mdx" /* webpackChunkName: "component---src-pages-components-skip-link-mdx" */),
  "component---src-pages-components-slider-market-mdx": () => import("./../../../src/pages/components/slider-market.mdx" /* webpackChunkName: "component---src-pages-components-slider-market-mdx" */),
  "component---src-pages-components-slider-mdx": () => import("./../../../src/pages/components/slider.mdx" /* webpackChunkName: "component---src-pages-components-slider-mdx" */),
  "component---src-pages-components-tab-nav-mdx": () => import("./../../../src/pages/components/tab-nav.mdx" /* webpackChunkName: "component---src-pages-components-tab-nav-mdx" */),
  "component---src-pages-components-team-color-mdx": () => import("./../../../src/pages/components/team-color.mdx" /* webpackChunkName: "component---src-pages-components-team-color-mdx" */),
  "component---src-pages-components-team-logo-mdx": () => import("./../../../src/pages/components/team-logo.mdx" /* webpackChunkName: "component---src-pages-components-team-logo-mdx" */),
  "component---src-pages-components-text-body-mdx": () => import("./../../../src/pages/components/text/body.mdx" /* webpackChunkName: "component---src-pages-components-text-body-mdx" */),
  "component---src-pages-components-text-display-mdx": () => import("./../../../src/pages/components/text/display.mdx" /* webpackChunkName: "component---src-pages-components-text-display-mdx" */),
  "component---src-pages-components-text-heading-mdx": () => import("./../../../src/pages/components/text/heading.mdx" /* webpackChunkName: "component---src-pages-components-text-heading-mdx" */),
  "component---src-pages-components-text-index-mdx": () => import("./../../../src/pages/components/text/index.mdx" /* webpackChunkName: "component---src-pages-components-text-index-mdx" */),
  "component---src-pages-components-toggle-mdx": () => import("./../../../src/pages/components/toggle.mdx" /* webpackChunkName: "component---src-pages-components-toggle-mdx" */),
  "component---src-pages-download-index-mdx": () => import("./../../../src/pages/download/index.mdx" /* webpackChunkName: "component---src-pages-download-index-mdx" */),
  "component---src-pages-foundations-color-system-mdx": () => import("./../../../src/pages/foundations/color-system.mdx" /* webpackChunkName: "component---src-pages-foundations-color-system-mdx" */),
  "component---src-pages-foundations-depth-mdx": () => import("./../../../src/pages/foundations/depth.mdx" /* webpackChunkName: "component---src-pages-foundations-depth-mdx" */),
  "component---src-pages-foundations-iconography-mdx": () => import("./../../../src/pages/foundations/iconography.mdx" /* webpackChunkName: "component---src-pages-foundations-iconography-mdx" */),
  "component---src-pages-foundations-index-mdx": () => import("./../../../src/pages/foundations/index.mdx" /* webpackChunkName: "component---src-pages-foundations-index-mdx" */),
  "component---src-pages-foundations-shape-mdx": () => import("./../../../src/pages/foundations/shape.mdx" /* webpackChunkName: "component---src-pages-foundations-shape-mdx" */),
  "component---src-pages-foundations-spacing-mdx": () => import("./../../../src/pages/foundations/spacing.mdx" /* webpackChunkName: "component---src-pages-foundations-spacing-mdx" */),
  "component---src-pages-foundations-typography-mdx": () => import("./../../../src/pages/foundations/typography.mdx" /* webpackChunkName: "component---src-pages-foundations-typography-mdx" */),
  "component---src-pages-getting-started-getting-started-for-designers-index-mdx": () => import("./../../../src/pages/getting-started/getting-started-for-designers/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-getting-started-for-designers-index-mdx" */),
  "component---src-pages-getting-started-getting-started-for-developers-index-mdx": () => import("./../../../src/pages/getting-started/getting-started-for-developers/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-getting-started-for-developers-index-mdx" */),
  "component---src-pages-getting-started-getting-started-for-developers-quick-start-ios-mdx": () => import("./../../../src/pages/getting-started/getting-started-for-developers/quick-start-ios.mdx" /* webpackChunkName: "component---src-pages-getting-started-getting-started-for-developers-quick-start-ios-mdx" */),
  "component---src-pages-getting-started-getting-started-for-developers-quick-start-mdx": () => import("./../../../src/pages/getting-started/getting-started-for-developers/quick-start.mdx" /* webpackChunkName: "component---src-pages-getting-started-getting-started-for-developers-quick-start-mdx" */),
  "component---src-pages-getting-started-getting-started-for-developers-tailwind-setup-mdx": () => import("./../../../src/pages/getting-started/getting-started-for-developers/tailwind-setup.mdx" /* webpackChunkName: "component---src-pages-getting-started-getting-started-for-developers-tailwind-setup-mdx" */),
  "component---src-pages-getting-started-index-mdx": () => import("./../../../src/pages/getting-started/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-index-mdx" */),
  "component---src-pages-help-feedback-mdx": () => import("./../../../src/pages/help-feedback.mdx" /* webpackChunkName: "component---src-pages-help-feedback-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-tokens-border-radius-mdx": () => import("./../../../src/pages/tokens/border-radius.mdx" /* webpackChunkName: "component---src-pages-tokens-border-radius-mdx" */),
  "component---src-pages-tokens-border-width-mdx": () => import("./../../../src/pages/tokens/border-width.mdx" /* webpackChunkName: "component---src-pages-tokens-border-width-mdx" */),
  "component---src-pages-tokens-box-shadow-mdx": () => import("./../../../src/pages/tokens/box-shadow.mdx" /* webpackChunkName: "component---src-pages-tokens-box-shadow-mdx" */),
  "component---src-pages-tokens-colors-mdx": () => import("./../../../src/pages/tokens/colors.mdx" /* webpackChunkName: "component---src-pages-tokens-colors-mdx" */),
  "component---src-pages-tokens-index-mdx": () => import("./../../../src/pages/tokens/index.mdx" /* webpackChunkName: "component---src-pages-tokens-index-mdx" */),
  "component---src-pages-tokens-spacing-mdx": () => import("./../../../src/pages/tokens/spacing.mdx" /* webpackChunkName: "component---src-pages-tokens-spacing-mdx" */),
  "component---src-pages-tokens-typography-mdx": () => import("./../../../src/pages/tokens/typography.mdx" /* webpackChunkName: "component---src-pages-tokens-typography-mdx" */),
  "component---src-pages-whats-new-archive-mdx": () => import("./../../../src/pages/whats-new-archive.mdx" /* webpackChunkName: "component---src-pages-whats-new-archive-mdx" */),
  "component---src-pages-whats-new-mdx": () => import("./../../../src/pages/whats-new.mdx" /* webpackChunkName: "component---src-pages-whats-new-mdx" */)
}

